const awsmobile = {
    "aws_project_region": "us-east-1",
    API: {
        endpoints: [
            {
                name: "RubeGoldbergMachineService",
                endpoint: "https://f22dsdebqg.execute-api.us-east-1.amazonaws.com/prod"
            }
        ]
    }
};

export default awsmobile;